.choose-from-library {
  height: 27rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin: 2rem;
}

@media (max-width: 1600px) {
  .choose-from-library {
    margin: 0.8rem;
    height: 22rem;
    width: 90%;
  }
}

.image-card {
  border: 0.175rem #4b4848 solid;
  width: 12rem;
  height: 12rem;
  border-radius: 1rem;
}

@media only screen and (max-width: 768px) {
  .image-card {
    width: 100%;
    height: 7rem;
  }
}

@media only screen and (max-width: 768px) {
  .choose-from-library {
    height: 30rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 0.5rem;
    margin: 0rem;
  }
}

@media (max-width: 1600px) {
  .image-card {
    width: 12rem;
    height: 12rem;
  }
}

.create-video-btn {
  background-color: #ff30c4;
  color: white;
}

.create-video-btn:hover {
  background-color: #ff30c4;
}

.image-selected {
  border-color: #ff30c4;
}

.image-card img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
