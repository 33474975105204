.success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000; /* Black background for full-page */
  position: relative; /* For positioning the logo */
}

.success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success-card {
  background-color: #1a1a1a; /* Dark card background */
  color: #fff; /* White text color for contrast */
  padding: 2rem 3rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 600px;
}

.success-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.success-button {
  display: inline-flex;
  align-items: center;
  background-color: #ff30c4; /* Highlight color */
  color: #fff; /* White text for button */
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.success-button:hover {
  background-color: #e025a5; /* Slightly darker shade on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.success-button i {
  margin-right: 0.5rem;
}

.logo-container {
  position: absolute; /* Position the logo */
  top: 50%;
  right: 10%; /* Adjust as needed */
  transform: translateY(-50%); /* Center vertically */
  padding: 1rem;
  display: flex;
  align-items: center; /* Center logo vertically in the container */
  justify-content: center; /* Center logo horizontally in the container */
}

.logo {
  max-width: 500px; /* Adjust this value to increase the size of the logo */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 1600px) {
  .logo-container {
    display: none;
  }
}
