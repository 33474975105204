.p4-_i2wrub {
  background-color: #ff30c4 !important;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
.modal-body {
  .col-md-6,
  .col-md-12 {
    padding: 0px 40px !important;
  }
}
.modal-dialog {
  max-width: 50%; /* Adjust this value to change the width */
}
.modal-content {
  border-radius: 10px;
  border: 1px solid #b9b0b0;
}
h5 {
  font-weight: 800;
}
span {
  font-size: 18px;
  font-weight: 400;
}

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-template {
  width: 87px;
  height: 32px;
  padding: 0px, 10px, 0px, 10px;
  border-radius: 20px;
  border-color: #ffff;
  background-color: transparent;
}

.button-template-mobile {
  width: 87px;
  height: 32px;
  padding: 0px, 10px, 0px, 10px;
  border-radius: 20px;
  border: none;
  background-color: transparent;
}
