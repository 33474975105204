.upload-audio {
  background-color: #ff30c4;
  color: white;
  border-radius: 0.5rem;
  border: none;
  width: 270px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 10px;
}

/* .upload-audio:disabled {
    background-color: #cfa1ac;
  } */
