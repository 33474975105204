.style-card {
  border: 0.25rem solid #6b6565;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 18rem;
  height: 16rem;
}

@media only screen and (max-width: 768px) {
  .style-card {
    border: 0.25rem solid #6b6565;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 18rem;
    height: 18rem;
  }
}

.carousel-caption-custom {
  background-color: rgb(228, 179, 209);
  color: #fff;
  padding: 3px;
  border-radius: 0px 6px 0px 6px;
}

.style-card-selected {
  border-color: #ff30c4;
}

.style-mini-icon {
  border: 1px solid #888;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 1.75rem;
  width: 1.75rem;
  display: flex;
}

.choose-art-style-box {
  height: 20rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin: 2rem 0;
}

.custom-tabs .nav-link {
  color: #ff30c4;
}

.custom-tabs .nav-link.active {
  color: #fff;
}
