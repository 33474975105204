.upload-btn {
  width: 30rem;
  height: 20rem;
  border: 3px dotted #ff30c4;
  margin: 0.1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: -0.125rem -0.125rem 1rem #343333;
  transition-duration: 100ms;
}

@media only screen and (max-width: 768px) {
  .upload-btn {
    width: 20rem;
    height: 6rem;
  }
}

@media (max-width: 1600px) {
  .upload-btn {
    width: 25rem;
    height: 15rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.upload-audio {
  background-color: #ff30c4;
  color: white;
  border-radius: 0.5rem;
  border: none;
  width: 115px;
  height: 35px;
}

/* .upload-audio:disabled {
  background-color: #cfa1ac;
} */

.upload-btn:hover {
  box-shadow: 0.5rem 0.5rem 1rem #141313;
  background-color: #343333;
}

.upload-btn:active {
  box-shadow: 0.25rem 0.25rem 0.5rem #141313;
  background-color: #4b4848;
}

@media (max-width: 1600px) {
  .p-3.d-flex.text-center.mb-4 {
    margin-bottom: 1rem !important;
  }
}

@media (max-width: 1600px) {
  header.mb-4 {
    margin-bottom: 2rem !important;
  }
}

@media (max-width: 1600px) {
  .d-flex.flex-column.overflow-auto.gap-3 {
    gap: 0;
  }
}

@media (max-width: 1600px) {
  .d-flex.flex-column.p-1.align-items-center.justify-content-between.col-12 {
    height: 19rem !important;
    margin: 0.01rem !important;
  }
}

@media (max-width: 1600px) {
  .d-flex.flex-column.col-12 {
    padding: 0 !important;
  }

  .d-flex.flex-column.ms-auto.gap-3.col-12 {
    gap: 2rem !important;
  }
}

.upload-btn i {
  font-size: 3rem;
  color: #ff30c4;
}

.choose-from-library {
  height: 22rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin: 2rem;
}

@media (max-width: 1600px) {
  .choose-from-library {
    margin: 0.8rem;
    height: 17rem;
  }
}

.music-card:hover {
  box-shadow: 0.5rem 0.5rem 1rem #141313;
  background-color: #343333;
  transition-duration: 100ms;
}

.music-card:active {
  box-shadow: 0.25rem 0.25rem 0.5rem #141313;
  background-color: #4b4848;
  transition-duration: 100ms;
}

.search-inner-btn {
  position: absolute;
  right: 1rem;
  padding: 0.12rem;
}

.search-inner-btn:hover {
  background-color: #343333;
  transition-duration: 100ms;
  border: 1px solid #6b6565;
  border-radius: 0.5rem;
}

.search-inner-btn:active {
  background-color: #4b4848;
  transition-duration: 100ms;
  border: 1px solid #6b6565;
  border-radius: 0.5rem;
}

.music-card-name {
  white-space: wrap;
  padding: 0 1rem;
  width: 10rem;
  height: 3rem;
  overflow: hidden;
  text-align: center;
}

.music-card {
  border: 2px solid #6b6565;
}

.music-card-selected {
  border-color: #ff30c4;
}
