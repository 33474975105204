.muze-btn {
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  gap: 0.5rem;
  background-color: #ff30c4;
  margin: auto;
}

.btn-create-avatar {
  background-color: #ff30c4;
  color: #fff;
}

.btn-create-avatar:hover {
  background-color: #c61e97;
}

.btn-create-avatar:active {
  background-color: #79145c;
}

.muze-btn:hover {
  background-color: #c61e97;
}

.muze-btn:active {
  background-color: #79145c;
}

.choose-avatar-box {
  height: 25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin: 2rem;
}

.avatar-card:hover {
  box-shadow: 0.5rem 0.5rem 1rem #141313;
  background-color: #343333;
  transition-duration: 100ms;
}

.avatar-card:active {
  box-shadow: 0.25rem 0.25rem 0.5rem #141313;
  background-color: #4b4848;
  transition-duration: 100ms;
}

.search-inner-btn {
  position: absolute;
  right: 1rem;
  padding: 0.12rem;
}

.search-inner-btn:hover {
  background-color: #343333;
  transition-duration: 100ms;
  border: 1px solid #6b6565;
  border-radius: 0.5rem;
}

.search-inner-btn:active {
  background-color: #4b4848;
  transition-duration: 100ms;
  border: 1px solid #6b6565;
  border-radius: 0.5rem;
}

.avatar-card-name {
  white-space: wrap;
  padding: 0 1rem;
  width: 10rem;
  height: 3rem;
  overflow: hidden;
  text-align: center;
}

.avatar-card {
  height: 18rem;
  width: 12rem;
  border: 1px solid #4b4848;
}

.avatar-card-selected {
  height: 18rem;
  width: 12rem;
  border: 1px solid #ff30c4;
}
