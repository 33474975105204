#upload {
  background-color: #ff30c4;
  padding: 0.5rem;
  border-radius: 0.75rem;
  transition-duration: 100ms;
  margin: 0.5rem;
}

#upload:hover {
  background-color: #ff57d0;
  box-shadow: #000 2px 2px 4px;
  transition-duration: 100ms;
}

#upload:active {
  background-color: #d42da4;
  box-shadow: #434343 2px 2px 4px;
  transition-duration: 100ms;
}

#upload-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 0.5rem;
  border: #fff dashed 1px;
  padding: 0.25rem;
}

#upload-icon {
  background-color: #fff;
  color: #000;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}