#card {
  background: #232627;
  box-shadow: 0px 20px 24px 0px rgba(0, 0, 0, 0.50);
}

#img {
  width: 3rem;
  height: 3rem;
  border-radius: 1.875rem;
  background: #B5E4CA;
}

#btn {
  background-color: #232627;
  border: 2px solid #343839;
  color: #FEFEFE;
}

#btn:hover {
  background-color: #484e50;
  border-color: #484e50;
}

#btn:active {
  background-color: #242627;
  border: 2px solid #343839;
}