/* navbar button active */
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link, .nav-active {
  border-radius: 0.5rem;
  background: linear-gradient(270deg, #323337 0%, rgba(70, 79, 111, 0.50) 100%);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
}

/* body color for dark theme */
[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-bg: #141718;
}

/* https://react-bootstrap.github.io/docs/getting-started/theming */