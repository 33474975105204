.file-input-button {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  color: #26589F;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  background-color: transparent;
  width: 100%;
  border: 0.5rem solid #FF30C4;
  border-radius: 1rem;
  background-color: #FF30C4;
  padding: 0.2rem;
  text-align: center;
  font-weight: 700;
  margin: 2rem auto;
}

.file-input-button:hover {
  opacity: 0.7;
}

.file-input-button>svg {
  width: 100%;
}

.file-input-button-offset {
  border: 1px dashed white;
  border-radius: 0.725rem;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.upload-icon {
  border-radius: 50% !important;
  background-color: white;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.upload-icon #up-media {
  background-color: transparent;
}

.error-info {
  font-size: 0.8rem;
  color: rgb(255, 172, 172);
}

.file-drop-input {
  margin: 0.5rem;
}