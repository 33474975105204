.video-duration-input {
  background-color: #282c34; /* Cor de fundo */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: auto;
}

.input-form {
  display: flex;
  flex-direction: column;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  transition: border-color 0.3s;
}

.form-control:focus {
  border-color: #ff30c4; /* Cor ao focar */
  outline: none;
}

.is-invalid {
  border-color: #dc3545; /* Cor de erro */
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 5px;
}
