.prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.25rem 1rem;
  border: 1px solid #474a4c;
  height: 35px;
}

.prompt input {
  border: none;
  background-color: #0000;
}

.highlight-error {
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.1);
}

.prompt input:active {
  border: none;
}

.prompt-del-btn {
  position: absolute;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16%;
  border: 1px solid #474a4c;
  background-color: #141718;
}

.prompt-del-btn:hover {
  background-color: #35393b;
}

.prompt-del-btn:active {
  background-color: #474a4c;
}

.prompt-add-btn {
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  gap: 0.5rem;
  background-color: #ff30c4;
}

.prompt-add-btn:hover {
  background-color: #c61e97;
}

.prompt-add-btn:active {
  background-color: #79145c;
}

.timing-container {
  background-color: #66747c;
  /* width: 163px; */
  height: 37px;
  padding: 6px;
  border-radius: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .timing-container {
    background-color: #66747c;
    /* width: 163px;
    height: 37px; */
    padding: 6px;
    border-radius: 15px;
    margin-bottom: 10px;
    color: #fff;
  }
}

.timing-container > button {
  border-radius: 20px;
  border: none;
}

.timing-active {
  background-color: #ff30c4;
  color: #fff;
}
.timing-inactive {
  background-color: #66747c;
  color: #fff;
}

.time-error {
  border: 1px solid #f44336 !important;
}

.wavesurfer-container {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .wavesurfer-container {
    width: 100%;
    height: 90%;
  }
}

.play-pause-btn {
  background: #333;
  border: none;
  border-radius: 3px 0px 0px 3px;
}
