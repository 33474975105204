.modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
.modal-body {
  .col-md-6,
  .col-md-12 {
    padding: 0px 40px !important;
  }
}
.modal-dialog {
  max-width: 50%; /* Adjust this value to change the width */
}
.modal-content {
  border-radius: 10px;
  border: 1px solid #b9b0b0;
}
h5 {
  font-weight: 800;
}
span {
  font-size: 18px;
  font-weight: 400;
}

.title {
  padding-left: 0px;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: linear-gradient(135deg, #f9ce34, #ee2a7b, #6228d7);
  color: white;
}
