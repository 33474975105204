#saveChanges {
  background-color: #ff30c4;
  border-color: #ff30c4;
}

.btn-manage-account {
  background-color: #ff30c4;
  color: #fff;
}

.btn-manage-account:hover {
  background-color: #c61e97;
}

.btn-manage-account:active {
  background-color: #79145c;
}
