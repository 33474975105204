.step-dot {
  box-sizing: border-box;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
}

.step-line {
  width: 1px;
  height: 1rem;
  border: 1px solid #fff;
  position: relative;
  bottom: -100%;
  z-index: -1;
}