#background {
  background: var(--neutral-07100, #141718);
}

#card {
  background: var(--neutral-06100, #232627);
}

.myheader {
  height: 4rem;
}

#btn-c {
  border-radius: 0.25rem;
  background: #000;
  box-shadow: #1f2122 2px 2px 4px;
}

#btn-c:hover {
  border-radius: 0.25rem;
  background: #333333;
  box-shadow: #131313 0 0 0;
}

#btn-c:active {
  border-radius: 0.25rem;
  background: #222222;
}

#btn-p {
  border-radius: 0.25rem;
  background: #ff30c4;
}

#btn-p:hover {
  background: #ff84dc;
}

#btn-p:active {
  background: #6d1554;
}

.my-btn {
  width: 8rem;
  height: 2rem;
  flex-shrink: 0;
  border-width: 0;
  font-size: small;
  font-weight: normal;
  box-shadow: #1f2122 2px 2px 4px;
}

#progress > div {
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  background: linear-gradient(90deg, #7f47c7 0%, #b16bb3 100%);
}

#progress {
  border-radius: 0.25rem;
  background: #291f33;
}

#btn-create {
  border-radius: 0.75rem;
  padding: 0.5rem 1.5rem;
  border-width: 0;
  font-size: large;
  background: #ff30c4;
  box-shadow: #1f2122 2px 2px 4px;
  white-space: nowrap;
}

#btn-create:hover {
  background: #ff84dc;
}

#btn-create:active {
  background: #6d1554;
}

.thumbs {
  background-color: #141718;
  height: 8rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#btn-x {
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 1rem;
  text-align: center;
  vertical-align: bottom;
  border: 1px #1d1d1d solid;
  font-size: normal;
}

.my-clicable {
  background-color: #141718;
}

.my-clicable:hover {
  background-color: #262b2c;
  box-shadow: #131313 2px 2px 2px;
}

.my-clicable:active {
  background-color: #0d0f0f;
}

.card {
  height: 8rem;
  max-width: 14rem;
}

.selected-cards {
  display: flex;
  flex-wrap: wrap;
}

.actions-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
