.command {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 3rem;
  padding: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  color: #000;
}

@media only screen and (max-width: 768px) {
  .command {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 3rem;
    padding: 0.65rem;
    width: 1.5rem;
    height: 1.6rem;
    color: #000;
  }
}

.command:hover {
  background-color: gray;
  border: #f0f 1px solid;
  transition-duration: 100ms;
  box-shadow: 2px 2px 2px #000;
  color: #fff;
}

.command:active {
  background-color: #fff;
  border: #f0f 1px solid;
  transition-duration: 100ms;
  box-shadow: 1px 1px 2px #000;
  color: #000;
}

.player-open {
  width: 100%;
  height: 100%;
  min-width: 24rem;
  min-height: 34rem;
}

.player-closed {
  width: 12rem;
  height: 17rem;
}

@media only screen and (max-width: 768px) {
  .player-closed {
    width: 12rem;
    height: 11rem;
  }
}

.library-img-conteiner {
  display: flex;
  margin-left: 20px;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  align-content: start;
}

@media only screen and (max-width: 768px) {
  .library-img-conteiner {
    padding: 0.5rem;
    margin: 0;
    width: 100%;
    flex-wrap: wrap;
  }
}

.paginator-container {
  position: fixed;
  left: 60%;
  transform: translateX(-50%);
  bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .paginator-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    font-size: 0.5rem;
  }
}

.library-img-card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  position: relative;
  text-overflow: ellipsis;
}

@media (max-width: 1500px) {
  .library-img-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.1rem;
    position: relative;
    text-overflow: ellipsis;
  }
}

.modal-content {
  width: 60vw;
}

@media (max-width: 1500px) {
  .modal-content {
    width: 70vw;
  }
}

.library-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.asset-name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.library-img-delete {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
  background-color: white;
  color: #000;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  .library-img-delete {
    position: relative;
    top: 0.1rem;
    right: 0.3rem;
    z-index: 2;
    background-color: transparent;
    color: #fff;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.8;
  }
}

@media only screen and (max-width: 768px) {
  .library-img-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem;
    position: relative;
    text-overflow: ellipsis;
  }
}

.library-img-delete:hover {
  opacity: 1;
}

.library-img {
  max-width: 100%;
  max-height: 60%;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .library-img {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
}

.library-searcher {
  background-color: transparent;
}
.library-searcher:focus {
  outline-width: 0;
}
.status-upscale-done-border {
  border-color: #f0f !important; /* ou a cor desejada */
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.video-modal-content {
  position: relative;
  width: 80%;
  height: 80%;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #000;
  cursor: pointer;
}
