#muse-img {
  width: 4rem;
  height: 4rem;
}

.invalid {
  color: red;
  border: 1px solid red;
}

.inv {
  font-size: x-small;
  color: red;
}

.strength-indicator {
  margin-top: 10px; /* Espaçamento acima do indicador */
  padding: 10px; /* Espaço interno do indicador */
  background-color: #f0f0f0; /* Cor de fundo do indicador */
  border-radius: 5px; /* Arredondamento dos cantos do indicador */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidade */
}

.strength-indicator p {
  margin: 0; /* Remover margens padrão do parágrafo */
  font-size: 14px; /* Tamanho da fonte do texto do indicador */
}

.strength-indicator ul {
  list-style: none; /* Remover marcadores padrão da lista */
  margin: 0; /* Remover margens padrão da lista */
  padding: 0; /* Remover espaçamento interno padrão da lista */
}

.strength-indicator li {
  margin-bottom: 5px; /* Espaçamento entre itens da lista */
}

.ps {
  /* PREVENT SELECT */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.loggin-btn {
  background-color: #ff30c4;
  border-color: #ff30c4;
  color: #fff;
  border-radius: 10px;
}

.loggin-btn:disabled {
  background-color: transparent;
  border-color: grey;
  color: white;
}

.custom-active {
  background-color: #343839;
  color: white;
  border: none;
}

.custom-inactive {
  background-color: black;
  color: gray;
  border: none;
}

.button-group-container {
  border: 5px solid black;
  border-radius: 10px;
}

.button-google {
  border: 1px solid gray;
}

.btn-group {
  flex: 1;
  min-width: 0;
  width: 100%;
}
