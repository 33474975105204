.choose-from-library-mobile {
  height: 27rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin: 2rem;
}

@media (max-width: 1600px) {
  .choose-from-library-mobile {
    margin: 0.8rem;
    height: 22rem;
    width: 90%;
  }
}

.choose-from-library-restyle {
  height: 25rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin: 2rem;
}

@media (max-width: 1600px) {
  .choose-from-library-restyle {
    margin: 0.1rem;
    height: 25rem;
    width: 97%;
  }
}

.image-card {
  border: 0.175rem #4b4848 solid;
  width: 10rem;
  height: 10rem;
  border-radius: 1rem;
}

.upload-btn-mobile {
  width: 30rem;
  height: 20rem;
  border: 3px dotted #ff30c4;
  margin: 0.1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: -0.125rem -0.125rem 1rem #343333;
  transition-duration: 100ms;
}

.upload-btn-mobile:hover {
  box-shadow: 0.5rem 0.5rem 1rem #141313;
  background-color: #343333;
}

.upload-btn-mobile:active {
  box-shadow: 0.25rem 0.25rem 0.5rem #141313;
  background-color: #4b4848;
}

@media only screen and (max-width: 768px) {
  .upload-btn-mobile {
    width: 20rem;
    height: 7rem;
  }
}

.btn-circle-white {
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  cursor: pointer;
}

.btn-circle-white i {
  font-size: 1.2rem;
  color: #000;
}

@media only screen and (max-width: 768px) {
  .image-card {
    width: 100%;
    height: 7rem;
  }
}

@media only screen and (max-width: 768px) {
  .choose-from-library-new-mobile {
    height: 17rem;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 0.5rem;
    margin: 0rem;
  }
}

@media only screen and (max-width: 768px) {
  .choose-from-library-mobile {
    height: 40rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 0.5rem;
    margin: 0rem;
  }
}

@media (max-width: 1600px) {
  .image-card {
    width: 12rem;
    height: 12rem;
  }
}

.create-video-btn {
  background-color: #ff30c4;
  color: white;
}

.create-video-btn:hover {
  background-color: #ff30c4;
}

.image-selected {
  border-color: #ff30c4;
}

.range-slider {
  width: 100%;
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff30c4;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff30c4;
  cursor: pointer;
}

.image-card img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
