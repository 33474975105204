.upscale-button {
  width: 412px;
  height: 65px;
  top: 986px;
  left: 1521px;
  padding: 10px;
  border-radius: 8px;
  gap: 10px;
  background-color: #198654;
}

.create-video-btn {
  background-color: #ff30c4;
  color: white;
}

.create-video-btn:hover {
  background-color: #ff30c4;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: linear-gradient(135deg, #f9ce34, #ee2a7b, #6228d7);
  color: white;
}
